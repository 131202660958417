import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Skeleton,
  useTheme,
} from '@mui/material';
import {
  FiCheckCircle, FiChevronDown, FiPlusCircle, FiSettings,
} from 'react-icons/fi';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

export default function Services({
  t,
  data,
  isAdditionalServices,
  navigationDirection,
  setData,
  onGoNext,
}) {
  const { i18n } = useTranslation();

  const {
    error,
    data: categoryData,
    loading,
  } = useQuery(FETCH_SERVICES, {
    variables: {
      locale: i18n.language,
      filters: isAdditionalServices ? {
        workshop_services: {
          isCrossSellingService: { eq: true },
        },
      } : {},
    },
    onCompleted: (queryData) => {
      const categories = getCategories(data.calendarId, queryData);
      setData({
        ...data,
        categories: [...data.categories, ...categories],
      });
    },
  });
  const categories = getCategories(data.calendarId, categoryData);

  useEffect(() => {
    if (loading || navigationDirection === 'backward') {
      return;
    }

    if (categories.length === 0) {
      onGoNext();
    }
  }, [onGoNext, navigationDirection, loading, categories]);

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <Skeleton />
    );
  }

  const handleToggleService = (id) => () => {
    let newServices = [];

    if (data.selectedServices?.find((serviceId) => serviceId === id)) {
      newServices = data.selectedServices.filter((serviceId) => serviceId !== id);
    } else {
      newServices = [
        ...data.selectedServices ?? [],
        id,
      ];
    }

    setData({
      ...data,
      selectedServices: newServices,
      isAnyServiceDisallowedOnSaturday: Boolean(newServices
        .find((sid) => categories
          .find((c) => c.attributes.workshop_services.data
            .find((s) => s.id === sid && s.attributes.isDisallowedOnSaturday)))),
    });
  };

  const gridSm = categories.length === 1 ? 12 : 6;

  return (
    <div>
      <Box mb={3}>
        <Typography component="p" variant="h6">
          {t(`components.organisms.WorkShopAppointmentTool.steps.Services.${isAdditionalServices ? 'additionalServices.title' : 'question'}`)}
        </Typography>
      </Box>
      <Grid2 container spacing={3}>
        {categories.length === 0 && (
          <Box p={2}>
            <Typography
              color="textSecondary"
              sx={{ opacity: 0.5 }}
              variant="body2"
            >
              Keine Leistungen verfügbar.
            </Typography>
          </Box>
        )}
        {categories.map((category) => (
          <Grid2
            key={category.id}
            size={{
              sm: gridSm,
              xs: 12,
            }}
          >
            <ServiceGroup
              category={category}
              data={data}
              i18n={i18n}
              onlySingleCategory={categories.length <= 2}
              onToggle={handleToggleService}
              services={category.attributes.workshop_services.data ?? []}
              t={t}
            />
          </Grid2>
        ))}
      </Grid2>
    </div>
  );
}

function ServiceGroup(props) {
  const {
    t,
    i18n,
    data,
    category,
    services,
    onlySingleCategory,
    onToggle,
  } = props;

  const hasServicesSelected = services
    .find(({ id }) => data.selectedServices.includes(id));

  return (
    <Accordion defaultExpanded={hasServicesSelected || onlySingleCategory}>
      <AccordionSummary
        data-e2e-id="category"
        data-e2e-value={category.id}
        expandIcon={<FiChevronDown />}
      >
        <Box alignItems="center" display="flex">
          <Box mr={1}>
            {category.attributes.icon?.data ? (
              <Avatar
                imgProps={{
                  style: { objectFit: 'contain' },
                }}
                src={category.attributes.icon.data.attributes.formats?.small?.url
                  ?? category.attributes.icon.data.attributes.formats?.thumbnail?.url
                  ?? category.attributes.icon.data.attributes.url}
                sx={{
                  width: 20,
                  height: 20,
                  opacity: 0.75,
                }}
                variant="square"
              />
            ) : (
              <FiSettings style={{ display: 'block' }} />
            )}
          </Box>
          <Box>
            <Typography component="p" variant="subtitle2">
              {category.attributes.name}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          flexDirection: 'column',
          alignItems: 'justify',
        }}
      >
        {category.attributes.description && (
          <Typography gutterBottom variant="body2">
            {category.attributes.description}
          </Typography>
        )}
        <List disablePadding sx={{ flex: 1 }}>
          {services.map((service) => (
            <Service
              key={service.id}
              i18n={i18n}
              isSelected={data.selectedServices.includes(service.id)}
              onToggle={onToggle}
              service={service}
              t={t}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

function Service(props) {
  const {
    t,
    i18n,
    service,
    isSelected,
    onToggle,
  } = props;
  const theme = useTheme();
  const [isDescriptionVisible, setDescriptionVisibility] = useState(false);

  const toggleDescriptionVisibility = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDescriptionVisibility(!isDescriptionVisible);
  };

  const serviceMinPrice = service.attributes.details?.minPrice
    ?.toLocaleString(i18n.language, {
      style: 'currency',
      currency: 'EUR',
    });

  return (
    <ListItem
      button
      onClick={onToggle(service.id)}
      sx={{
        borderRadius: `${theme.shape.borderRadius}px`,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        backgroundColor: isSelected ? `${theme.palette.primary.main} !important` : 'inherit',
      }}
    >
      {service.attributes.icon?.data && (
        <ListItemAvatar>
          <Avatar
            imgProps={{
              style: { objectFit: 'contain' },
            }}
            src={service.attributes.icon.data.attributes.formats?.small?.url
              ?? service.attributes.icon.data.attributes.formats?.thumbnail?.url
              ?? service.attributes.icon.data.attributes.url}
            style={{
              opacity: 0.75,
            }}
            variant="square"
          />
        </ListItemAvatar>
      )}
      <Box flex={1}>
        <ListItemText
          primary={service.attributes.name}
          primaryTypographyProps={{
            variant: 'subtitle2',
          }}
          secondary={service.attributes.description}
          sx={{
            marginRight: theme.spacing(1),
            whiteSpace: 'pre-wrap',
          }}
        />
        {service.attributes.details && (
          <>
            <Collapse in={isDescriptionVisible} unmountOnExit>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'subtitle2',
                }}
                secondary={service.attributes.details.description}
                sx={{
                  marginRight: theme.spacing(1),
                  whiteSpace: 'pre-wrap',
                }}
              />
            </Collapse>
            {(serviceMinPrice || service.attributes.details.description) && (
              <>
                <Divider sx={{ my: 1 }} />
                <Box alignItems="center" display="flex">
                  {service.attributes.details.description && (
                    <Button
                      endIcon={(
                        <FiChevronDown
                          style={{
                            transition: theme.transitions.create('all', {
                              duration: theme.transitions.duration.complex,
                            }),
                            transform: isDescriptionVisible && 'rotate(180deg)',
                          }}
                        />
                      )}
                      onClick={toggleDescriptionVisibility}
                      size="small"
                      variant="outlined"
                    >
                      {t(`components.organisms.WorkShopAppointmentTool.${isDescriptionVisible ? 'collapse' : 'expand'}`)}
                    </Button>
                  )}
                  <Typography
                    component="div"
                    sx={{
                      textAlign: 'right',
                      ml: 'auto',
                    }}
                    variant="subtitle2"
                  >
                    <strong>
                      {t('components.organisms.WorkShopAppointmentTool.steps.Services.from')}
                      {' '}
                      {serviceMinPrice}
                    </strong>
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <ListItemSecondaryAction>
        <IconButton
          data-e2e-id="serviceButton"
          data-e2e-value={service.id}
          onClick={onToggle(service.id)}
        >
          {isSelected ? (
            <FiCheckCircle
              style={{
                color: '#4CAF50',
              }}
            />
          ) : (
            <FiPlusCircle
              style={{
                color: theme.palette.primary.main,
              }}
            />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

Services.validateStep = function (data) {
  return data.selectedServices?.length > 0;
};

function getCategories(calendarId, data) {
  return data?.workshopCategories.data
    .map((item) => ({
      ...item,
      attributes: {
        ...item.attributes,
        workshop_services: {
          data: item.attributes.workshop_services.data
            .filter((service) => service.attributes.workshop_calendars.data
              .find((b) => b.id === calendarId)),
        },
      },
    }))
    .filter((item) => item.attributes.workshop_services.data.length > 0)
    ?? [];
}

const FETCH_SERVICES = gql`
  query GetItems($locale: I18NLocaleCode!, $filters: WorkshopCategoryFiltersInput!) {
    workshopCategories(
      sort: ["index", "name"],
      locale: $locale,
      filters: $filters
    ) {
      data {
        id
        attributes {
          name
          description
          index
          icon {
            data {
              id
              attributes {
                name
                width
                height
                formats
                ext
                mime
                size
                url
              }
            }
          }
          workshop_services {
            data {
              id
              attributes {
                name
                description
                wtpId
                isCrossSellingService
                isDisallowedOnSaturday
                details {
                  description
                  minPrice
                }
                workshop_calendars {
                  data{
                    id
                  }
                }
                icon {
                  data {
                    id
                    attributes {
                      name
                      width
                      height
                      formats
                      ext
                      mime
                      size
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
